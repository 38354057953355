import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getGenres, getGenreSeries } from "@/Gql/genres";
import { getHomeGenres } from "@/Gql/home";
export const useGenresStore = defineStore("genres", {
    state: () => {
        return {
            genres: Array,
            genresSeries: Object,
            series: Array,
            homeGenres: Object,
            length: 0,
            limit: 4

        }
    },
    getters: {
        genresData: (state) => state.genres,
        genresSeriesData: (state) => state.genresSeries,
        getSeries: (state) => state.series,
        getHomeGenresData: (state) => state.homeGenres,
        getLength: (state) => state.length

    },
    actions: {
        async showHomeGenres(page) {
            await apolloClient
                .query({
                    query: getHomeGenres,

                    fetchPolicy: "cache-first",

                }).then(async res => {
                    this.length = res.data.getHomeGenres.length,
                        this.homeGenres = res.data.getHomeGenres.slice(0, this.limit * page);
                })
                .catch(error => {
                    this.error = error
                })
        },
        async showGenresData(page = 1, limit = 6) {
            this.genres = [];
            await apolloClient
                .query({
                    query: getGenres,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",

                }).then(async res => {
                    this.genres = res.data.getGenres.data
                })
                .catch(error => {
                    this.error = error
                })
        },
        async showGenresSeries(id) {
            this.genresSeries = null;
            await apolloClient
                .query({
                    query: getGenreSeries,
                    variables: { getGenreId: id },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.genresSeries = res.data.getGenre,
                        this.series = res.data.getGenre.series.filter((serie) => serie.published === true)



                })
                .catch(error => {
                    this.error = error
                })
        }
    }
})

