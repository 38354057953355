<template>
  <div class="flex mt-2" v-show="!loaded">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 mr-1 mt-1"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#E54057"
      stroke-width="1.5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
      />
    </svg>

    <router-link to="/brows/programs" class="text-white text-lg md:text-[30px]">
      Mafi kusa
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["loaded"],
};
</script>

<style></style>
