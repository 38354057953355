<template>
  <div class="flex justify-center items-center rounded-lg mx-2 mt-2 md:mt-6 relative">
    <!-- Main Content -->
    <div
      class="md:w-5/12 w-full rounded-md h-auto md:p-0 p-2 text-white md:border md:border-gray-600"
    >
      <div class="overflow-auto">
        <div class="flex flex-row justify-center my-1.5">
          <div class="flex flex-row justify-center my-6">
            <img src="@/assets/logo-6.png" alt="ad plus logo" class="object-cover w-24" />
          </div>
        </div>
        <div class="my-2 text- flex-col flex-items">
          <h1 class="text-2xl font-bold text-white">Kafa Asusu</h1>
          <p class="text-sm max-w-md text-center text-gray-500 my-2">
            Cika Bayananka Don Fara Kallo
          </p>
        </div>
      </div>
      <div class="my-1">
        <h1 class="text-[#E54057] text-md mb-3 font-Dosis flex-items">
          Shigar Da Lambar Tabbatarwa
        </h1>
        <div class="flex justify-center items-center">
          <MazInputCode v-model="code" :size="size" class="" />
        </div>
        <button
          class="w-full py-3 px-6 rounded-md text-lg flex-items my-6"
          @click="resendOTP"
          :disabled="time > 0"
        >
          <template v-if="time === 0"
            ><span class="text-[#E54057]">Resend </span>
          </template>
          <template v-else>
            <span class="text-gray-100 text-3xl">{{ formatTime() }}</span>
          </template>
        </button>
        <div class="flex flex-col justify-center items-center gap-3 mt-32 relative z-50">
          <button
            class="md:w-96 w-full btn-color py-3 px-6 rounded-md inline-flex justify-center items-center gap-2"
            @click="submitCode"
            :disabled="!isCodeFull || loaded"
          >
            <span v-if="!loaded"> Tabbatar </span>
            <div role="status" v-show="loaded">
              <loadder-svg />
              <span class="sr-only">Loading...</span>
            </div>
          </button>
          <div class="md:w-96 w-full mb-4">
            <button
              @click="redirectHome"
              class="w-full inline-flex justify-center items-center border border-[#E54057] text-center text-[#E54057] rounded-md py-3"
            >
              <span> Koma Baya </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MazInputCode from "maz-ui/components/MazInputCode";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";
import LoadderSvg from "@/components/svg/LoadderSvg.vue";
import { AUTHTOKEN, USER } from "@/constants";

export default {
  components: {
    MazInputCode,
    LoadderSvg,
  },
  data() {
    return {
      code: "",
      showMessage: false,
      canResend: true,
      time: 60,
      size: "sm",
      toast: useToast(),
      loaded: false,
    };
  },
  computed: {
    isCodeFull() {
      return this.code.length === 4; // Assuming OTP is 6 digits long
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["verifyOtp", "registerUserAction", "setData"]),
    redirectHome() {
      this.$router.push("/");
    },
    async submitCode() {
      this.loaded = true;
      // Implement the logic to submit the OTP
      await this.verifyOtp(this.code).then((response) => {
        if (response.data.success) {
          localStorage.setItem(AUTHTOKEN, response.data.token);
          localStorage.setItem(USER, response.data.user);

          this.toast.success("An Kammala Biyan Kuɗin Shiga Da Nasara ");
          this.setData(response);
          this.$router.push("/");
          this.loaded = false;
        } else {
          this.toast.error("Lambar Tabbatarwa Ba Ta Dace Ba ");
        }
      });
      this.loaded = false;
    },
    async resendOTP() {
      this.time = 60; // Reset timer
      this.startTimer();
      // Implement the logic to resend the OTP
      const user = localStorage.getItem(USER);
      const userData = JSON.parse(user);
      await this.registerUserAction(userData).then((response) => {
        if (response.data.success) {
          this.toast.success("	Za A Aika Maka Lambar Tabbatarwa Don Tabbatar Da Asusunka");
        } else {
          this.toast.error("Da Fatan Za Ka Sake Gwadawa!");
        }
      });
    },

    startTimer() {
      const timerInterval = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(timerInterval);
          // Timer finished logic here
        }
      }, 1000);
    },
    formatTime() {
      const minutes = Math.floor(this.time / 60);
      const seconds = this.time % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    closeAlert() {
      this.showMessage = false;
    },
  },
  created() {
    this.startTimer();
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #e54057;
}

.bg-sub {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: right;
}

.font-small {
  font-size: 16px;
}
</style>
