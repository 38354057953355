<template>
  <div
    class="flex justify-center items-center rounded-lg mx-2 mt-2 md:mt-6 relative"
  >
    <div
      class="container mx-auto px-2 p-1 bg-[#171614] shadow-md drop-shadow-2xl lg:p-2 mt-6 rounded-md place-items-center border border-gray-600"
    >
      <div class="container mx-auto">
        <div class="flex flex-row justify-center my-6">
          <img
            src="@/assets/logo-6.png"
            alt="ad plus logo"
            class="object-cover w-24"
          />
        </div>
        <div class="my-2 text- flex-col flex-items">
          <h1 class="text-2xl font-bold text-white">Kafa Asusu</h1>
          <p class="text-sm max-w-md text-center text-gray-500 my-2">
            Cika bayananka don fara kallo
          </p>
        </div>

        <div class="m-3 relative md:w-96 mx-auto mt-8" dir="rtl">
          <div class="flex justify-between items-center">
            <hr class="flex-grow border-gray-600" />
            <p class="mx-3 text-gray-300">Kafa Asusu</p>
            <hr class="flex-grow border-gray-600" />
          </div>
          <div class="flex flex-col gap-3 justify-center items-center my-4">
            <div class="relative w-full">
              <user-svg />
              <input
                type="text"
                class="register-inputs focus:ring-[#E54057]"
                v-model="user.name"
                placeholder="Cikakken suna"
                style=""
              />
            </div>
            <div class="w-full" dir="ltr">
              <div class="mt-3 m-3 relative w-full mx-auto">
                <MazPhoneNumberInput
                  v-model="phoneNumber"
                  v-model:countryCode="countryCode"
                  show-code-on-list
                  countrySelectorWidth="8rem"
                  :preferred-countries="[
                    'NG',
                    'EG',
                    'ET',
                    'KE',
                    'ZA',
                    'GH',
                    'UG',
                    'TZ',
                    'DZ',
                    'MA',
                    'SD',
                    'CM',
                    'FR',
                    'BE',
                    'DE',
                    'US',
                    'GB',
                  ]"
                  @update="handleUpdate"
                  orientation="responsive"
                  class="w-full"
                  color="primary"
                  size="md"
                  fetch-country
                />
              </div>
            </div>
            <div class="relative w-full">
              <email-icon class="" />
              <input
                type="email"
                v-model="user.email"
                class="register-inputs focus:ring-[#E54057]"
                :placeholder="`Imel (Optional)`"
              />
              <small class="text-gray-400 text-sm text-center flex-items mt-4">
                Za a aika maka lambar tabbatarwa</small
              >
            </div>
            <div class="relative w-full mt-16 mx-auto">
              <button
                @click="subscribeAction"
                class="inline-flex justify-center items-center w-full bg-[#E54057] text-gray-100 rounded-md py-3 btn-color"
              >
                Kafa Asusu
              </button>
            </div>
            <div class="relative w-full md:mt-2 mt-1 mx-auto">
              <button
                class="inline-flex justify-center items-center w-full border border-[#E54057] text-center text-[#FFF] rounded-md py-3"
              >
                <span> Koma Baya </span>
              </button>
            </div>
            <div class="flex justify-center text-white text-center" dir="rtl">
              <span
                @click="$router.push('/user/login')"
                class="text-color mb-3 cursor-pointer ml-1"
              >
                {{ " Shiga" }}
              </span>
              ? Kana da asusu
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

import EmailIcon from "@/components/svg/EmailIcon.vue";
import UserSvg from "@/components/svg/UserSvg.vue";
import { useToast } from "vue-toastification";
import { validateUsername } from "@/utils/validations";
import { mapActions } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { USER } from "@/constants";

export default {
  components: { EmailIcon, UserSvg, MazPhoneNumberInput },
  data() {
    return {
      user: {
        msisdn: "",
        email: "",
        name: "",
      },
      toast: useToast(),
      results: null,
    };
  },
  mounted() {
    var phoneInputs = document.querySelectorAll(".m-phone-input");

    // Loop through the NodeList and change the min-width style
    phoneInputs.forEach(function (phoneInput) {
      phoneInput.style.minWidth = "6rem";
    });
  },
  methods: {
    ...mapActions(useAuthStore, ["registerUserAction"]),
    handleUpdate(event) {
      this.results = event;
    },
    async subscribeAction() {
      validateUsername(this.user.name);
      if (this.results.isValid) {
        this.user.msisdn = this.results.e164;
        let user = JSON.stringify(this.user);
        localStorage.setItem(USER, user);
        await this.registerUserAction(this.user).then((response) => {
          if (response.data.success) {
            this.toast.success(
              "Za A Aika Maka Lambar Tabbatarwa Don Tabbatar Da Asusunka"
            );
            this.$router.push("/user/verify-opt");
          } else {
            this.toast.error("Wani Abu Ya Faru.");
          }
        });
      } else {
        this.toast.error("	Cika Bayananka Don Fara Kallo");
      }
    },
  },
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.bg-sub {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: right;
}

.font-small {
  font-size: 16px;
}

.maz-input {
  font-size: 16px; /* Adjust the font size */
  color: red !important; /* Change the text color */
  border-color: #ccc; /* Change the border color */
}

/* Customize the country selector */
.maz-input__country-selector {
  width: 8rem; /* Adjust the width if needed */
  background-color: #030f25 !important; /* Change background color */
  color: #000; /* Text color inside selector */
}

/* Customize the flags */
.maz-input__flag {
  width: 20px; /* Adjust flag size */
  height: 14px; /* Adjust flag height */
}

/* Adjust input field */
.maz-input__field {
  font-size: 18px; /* Change input font size */
  padding: 10px; /* Adjust padding for better spacing */
  color: #000 !important; /* Change input text color */
}

/* Placeholder styling */
.maz-input__field::placeholder {
  color: #888; /* Change placeholder color */
  font-size: 16px; /* Adjust placeholder font size */
}

/* Your scoped CSS here */
.maz-phone-number-input input {
  border-color: blue !important;
  color: blue !important;
}
</style>
