<template>
    <div class=" md:mx-auto mb-2 md:padding-x">
        <!-- <carsouel-view /> -->
        <HomeLayout :data="false"></HomeLayout>


        <h3 class="text-center text-2xl text-white mt-12">Not Found</h3>
        <div class="text-4xl text-center text-pink-600">404</div>
    </div>
</template>

<script>
import HomeLayout from '@/components/HomeLayout.vue';
export default {
    components: { HomeLayout },
    mounted() {
        this.getSubscribtionPage();
    },
    methods: {
        getSubscribtionPage() {
            var phone = '';
            const absoluteURL = new URL(this.$route.href, window.location.origin).href;
            if (absoluteURL.includes("?msidn=")) {

                phone = absoluteURL.substring(absoluteURL.indexOf('=') + 1);

                this.$router.push({
                    name: 'subscribe_ok', params: {
                        msidn: phone
                    }
                })
            }


        }
    }

}
</script>

<style></style>