<template>
  <div class="flex justify-center items-center rounded-lg mx-2 md:mt-3 relative">
    <div
      class="md:w-8/12 lg:w-4/12 w-full md:mt-3 rounded-md h-auto md:p-0 px-4 text-white md:border md:border-gray-600"
    >
      <div class="py-6 h-auto">
        <div class="flex flex-row justify-center my-1.5">
          <img src="@/assets/logo-6.png" alt="logo" class="w-20" />
        </div>
        <div class="flex flex-row justify-center my-4">
          <h1 class="text-lg font-bold text-center text-gray-100 font-Dosis">
            Tsarin Biyan Kuɗin Shiga
          </h1>
        </div>

        <div class="my-3 text-center flex justify-center">
          <p class="text-gray-400 md:text-md text-sm max-w-md text-center font-Dosis">
            Zaɓi Tsarin Biyan Kuɗin Shiga Don Fara Kallo
          </p>
        </div>
        <div class="flex-items flex-col gap-4" dir="ltr">
          <!-- monthly  -->
          <div
            @click="selectedPlanFunction('monthly')"
            dir="ltr"
            :class="
              isSelected == 'monthly'
                ? 'border-yellow-400 justify-between'
                : 'border-gray-200 justify-end'
            "
            class="flex items-center justify-between border border-gray-200 p-2 text-white rounded-md md:w-10/12 w-full"
          >
            <div class="flex flex-col gap-2" dir="ltr">
              <div class="">{{ "Tsarin Kowane Watan" }}</div>
              <div class="">{{ "N1200  Kowane Watan" }}</div>
              <div class="text-gray-600 text-sm">
                {{ "	Kamila Ga Masu Kallon Sauƙi" }}
              </div>
            </div>
            <div
              class="svg bg-[#E54057] rounded-full w-6 h-6 text-center"
              v-show="isSelected == 'monthly'"
            >
              {{ "✓" }}
            </div>
          </div>
          <!-- end of monthly -->

          <!-- half year   -->
          <div
            @click="selectedPlanFunction('6-months')"
            dir="ltr"
            :class="
              isSelected == 'half_year'
                ? 'border-yellow-400 justify-between'
                : 'border-gray-200 justify-end'
            "
            class="flex items-center justify-between border border-gray-200 p-2 text-white rounded-md md:w-10/12 w-full"
          >
            <div class="flex flex-col gap-2" dir="ltr">
              <div class="">{{ "	Tsarin Watanni 6" }}</div>
              <div class="">{{ "N6300 Kowane Watan" }}</div>
              <div class="text-gray-600 text-sm">
                {{ "	Kamila Ga Masu Kallon Yau Da Kullun" }}
              </div>
            </div>
            <div
              class="svg bg-[#E54057] rounded-full w-6 h-6 text-center"
              v-show="isSelected == 'half_year'"
            >
              {{ "✓" }}
            </div>
          </div>
          <!-- end of half year -->
          <!-- yearly   -->
          <div
            @click="selectedPlanFunction('yearly')"
            dir="ltr"
            :class="
              isSelected == 'yearly'
                ? 'border-yellow-400 justify-between'
                : 'border-gray-200 justify-end'
            "
            class="flex items-center justify-between border border-gray-200 p-2 text-white rounded-md md:w-10/12 w-full"
          >
            <div class="flex flex-col gap-2" dir="ltr">
              <div class="">{{ "Tsarin Shekara" }}</div>
              <div class="">{{ "N12000 Kowane Watan" }}</div>

              <div class="text-gray-600 text-sm">
                {{ "Mafi Kyau Ga Masu Kallon Marar Tsayawa" }}
              </div>
            </div>
            <div
              class="svg bg-[#E54057] rounded-full w-6 h-6 text-center"
              v-show="isSelected == 'yearly'"
            >
              {{ "✓" }}
            </div>
          </div>
          <!-- end of yearly  -->
        </div>
        <!--Actions  Buttons -->
        <div class="m-3 relative md:w-10/12 w-full mt-6 mx-auto">
          <button
            @click.prevent="subscribeToSelectedPlan"
            class="inline-flex justify-center items-center gap-2 w-full btn-color text-gray-100 rounded-md py-3"
          >
            <span v-show="!loaded"> Biyan Kuɗin Shiga Yanzu! </span>
            <div role="status" v-show="loaded">
              <spinner-view />
            </div>
          </button>
        </div>
        <div class="m-3 relative md:w-10/12 w-full mt-6 mx-auto">
          <button
            @click.prevent="$router.push('/')"
            class="inline-flex justify-center items-center gap-2 w-full border border-[#E54057] text-center text-[#FFF] rounded-md py-3"
          >
            <span> Koma Baya </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { selectedPlan } from "@/constants";

export default {
  components: { SpinnerView },
  data() {
    return {
      loaded: false,
      isSelected: null,
      toast: useToast(),
      monthly: "monthly",
      half_year: "half_year",
      yearly: null,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["subscribeAction"]),
    selectedPlanFunction(option) {
      this.isSelected = option;
      localStorage.setItem(selectedPlan, option);
    },

    subscribeToSelectedPlan() {
      const option = localStorage.getItem(selectedPlan);
      if (option) {
        this.$router.push("/subscribe/headerinerchment");
      } else {
        this.toast.error("Da Fatan Za a Fara Zaɓi Shirin Farko");
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.tab {
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #fff;
  border-radius: 20px;
  color: #e54057;
}
</style>
