import gql from "graphql-tag";
export const getGenres = gql`
query Query($page: Int!, $limit: Int!) {
getGenres(page: $page, limit: $limit) {
data {
id
title
desc
createdAt
series {
  id
  title
  image
  cover
  desc
  view_count
  published
  eposides_count
  createdAt
  label
}
}
meta {
    last_page
    current_page
    total
  }
}
}
`

export const getGenreSeries = gql`
query Query($getGenreId: ID!) {
    getGenre(id: $getGenreId) {
    id
    title
    series {
      id
      title
      image
      cover
      desc
      view_count
      published
      eposides_count
      createdAt
      label
    }
    }
}
`