<template>
   <div class="flex mt-2" v-show="!loaded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#E54057"
          class="w-6 h-6 mr-1 mt-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
          />
        </svg>

        <router-link :to="`/categories/show/${genre.id}`" class="text-white text-lg md:text-[30px]">
          {{ genre.title }}</router-link
        >
      </div>
</template>

<script>
export default {
    props:["loaded","genre"]
}
</script>

<style>

</style>