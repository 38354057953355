<template>
  <div
    class="flex justify-center items-center rounded-lg mx-2 mt-2 md:mt-6 relative"
  >
    <div
      class="container mx-auto px-2 p-1 bg-[#171614] shadow-md drop-shadow-2xl lg:p-2 rounded-md place-items-center border md:border-gray-600"
    >
      <div class="container mx-auto md:border-gray-600">
        <div class="flex flex-row justify-center my-6">
          <img
            src="@/assets/logo-6.png"
            alt="ad plus logo"
            class="object-cover w-24"
          />
        </div>
        <div class="my-2 text- flex-col flex-items">
          <h1 class="text-2xl font-bold text-white">Shiga</h1>
          <p class="text-sm max-w-md text-center text-gray-500 my-2">
            Shigar da lambar wayarka
          </p>
        </div>

        <div class="m-3 relative md:w-96 mx-auto mt-8" dir="rtl">
          <div class="flex justify-between items-center">
            <hr class="flex-grow border-gray-600" />
            <p class="mx-3 text-gray-300">Lambar waya</p>
            <hr class="flex-grow border-gray-600" />
          </div>
          <div class="flex flex-col gap-3 justify-center items-center my-4">
            <div class="relative w-full bg" dir="ltr">
              <MazPhoneNumberInput
                v-model="phoneNumber"
                v-model:countryCode="countryCode"
                show-code-on-list
                countrySelectorWidth="8rem"
                :preferred-countries="[
                  'NG',
                  'EG',
                  'ET',
                  'KE',
                  'ZA',
                  'GH',
                  'UG',
                  'TZ',
                  'DZ',
                  'MA',
                  'SD',
                  'CM',
                  'FR',
                  'BE',
                  'DE',
                  'US',
                  'GB',
                ]"
                @update="handleUpdate"
                orientation="responsive"
                class="w-full"
                color="primary"
                size="md"
                fetch-country 
              />
            </div>
            <div class="relative w-full">
              <small class="text-gray-400 text-sm text-center flex-items mt-4">
                Za a aika maka lambar tabbatarwa</small
              >
            </div>
            <div class="relative w-full mt-16 mx-auto">
              <button
                @click="loginAction"
                class="inline-flex justify-center items-center w-full bg-[#E54057] text-gray-100 rounded-md py-3 btn-color"
                :disabled="loaded"
              >
              <div role="status" v-show="loaded">
              <loadder-svg /> 
              <span class="sr-only">Loading...</span>
            </div>
                <span v-if="!loaded"> Koma Baya </span>
              </button>
            </div>
            <div class="relative w-full md:mt-2 mt-1 mx-auto">
              <button
                @click="redirectHome()"
                class="inline-flex justify-center items-center w-full border border-[#E54057] text-center text-[#FFF] rounded-md py-3"
                :disabled=" loaded"
          >
        
                <span > Koma Baya </span>
              </button>
            </div>
            <span
              dir="ltr"
              @click="$router.push('/user/subscribe')"
              class="text-white flex justify-center mb-3 cursor-pointer"
            >
              Babu asusu?
              <strong class="text-[#E54057] mx-1"> {{ " Kafa Asusu" }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { USER } from "@/constants";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";
import LoadderSvg from '@/components/svg/LoadderSvg.vue';
export default {
  components: { MazPhoneNumberInput,LoadderSvg },
  data() {
    return {
      user: {
        msisdn: "",
      },
      taost: useToast(),
      showMessage: false,
      // countryCode: "NG",
      results: null,
      loaded:null
    };
  },
  mounted() {
    var phoneInputs = document.querySelectorAll(".m-phone-input");

    // Loop through the NodeList and change the min-width style
    phoneInputs.forEach(function (phoneInput) {
      phoneInput.style.minWidth = "6rem";
    });
  },
  methods: {
    ...mapActions(useAuthStore, ["loginUserAction"]),
    handleUpdate(event) {
      this.results = event;
    },
    redirectHome() {
      this.$router.push("/");
    },
    async loginAction() {
      this.loaded=true
      // const validated = validatePhone(this.user.msisdn.toString());
      this.user.msisdn = this.results.e164;
      if (this.results.isValid) {

        localStorage.setItem(USER, JSON.stringify(this.user));
        await this.loginUserAction(this.user).then((response) => {
          if (response.data.success) {
            this.taost.success("otp was sent ");
            this.$router.push("/user/verify-opt");
          } else {
            this.taost.error("you are not subscribed ");
            this.$router.push("/user/subscribe");
          }
        });
      } else {
        this.taost.error("the provided msisdn number is invalid");
      }
      this.loaded=false
    },
  },
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.bg-sub {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: right;
}

.font-small {
  font-size: 16px;
}
.m-input-wrapper-input{
  background-color: red !important;
}
</style>
