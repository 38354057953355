import gql from "graphql-tag";

export const getFiltter = gql`
query Query($page: Int!, $limit: Int!, $text: String, $genreId: String, $type: String, $order: String, $orderBy: String) {
  filters(page: $page, limit: $limit, text: $text, genreId: $genreId, type: $type, order: $order, orderBy: $orderBy) {

data {
... on Serie {
id
title
image
cover
eposides_count
label
}
}
meta {
total
per_page
current_page
last_page
first_page
first_page_url
last_page_url
next_page_url
previous_page_url
}
}
}`



export const search = gql`
query Query($page: Int!, $limit: Int!, $text: String) {
search(page: $page, limit: $limit, text: $text) {
data {
... on Serie {
id
title
image

label
cover
eposides_count
label

}
}
meta {
total
per_page
current_page
last_page
first_page
first_page_url
last_page_url
next_page_url
previous_page_url
}
}
}

`