<template>
  <div class="mt-2 hidden md:block">
    <slot />
    <div class="grid grid-cols-1 gap-y-6 gap-x-2 -mt-2 mx-2" v-if="isActive">
      <button
        @click="account"
        class="border border-color text-white text-center rounded-md xsm:px-3 md:px-[30px] md:py-2.5 px-[150px] py-2 text-sm md:text-[20px] lg:text-[15px] xsm:text-[12px]"
      >
        Asusuna
      </button>
      
    </div>
    <div class="grid grid-cols-2 gap-y-6 gap-x-2 -mt-1 hidden md:block" v-else>
      <button
        @click="$router.push('/user/login')"
        class="border border-color text-white mx-2 text-center rounded-md xsm:px-3 md:px-[30px] md:py-2.5 px-[150px] py-2 text-sm md:text-[20px] lg:text-[15px] xsm:text-[12px]"
      >
        Shiga
      </button>

      <button
        @click="$router.push('/user/subscribe')"
        class="btn-color btn-color text-white text-center rounded-md xsm:px-3 md:px-[30px] md:py-[11px] px-[150px] py-1.5 text-sm md:text-[20px] xsm:text-[12px]"
      >
      Rijista!
      </button>
    </div>
  </div>
</template>

<script>
import { ISACTIVE } from "@/constants";
import { useAuthStore } from "@/stores/auth";
import { mapState } from "pinia";

import { useToast } from "vue-toastification";

export default {
  components: {
    // SpinnerView,
  },
  data() {
    return {
      user: false,
      isActive: false,
      isDone: false,
      toast: useToast(),
    };
  },
  computed: {
    ...mapState(useAuthStore, ["getISActive"]),
  },
  mounted() {
    this.checkIsActiveAction();
  },
  watch: {
    // eslint-disable-next-line
    getISActive(newValue, oldValue) {
    },
  },
  methods: {
    checkIsActiveAction() {
      return (this.isActive = localStorage.getItem(ISACTIVE));
    },

    account() {
      this.$router.push("/confirm/unsubscribe");
    },
  }, //end of methods
};
</script>

<style></style>
