<template>
    <div class="flex justify-center rounded-lg mx-2 mt-12">
        <div class="">
            <div class="
            container
            mx-auto
            px-4
            p-1
            bg-sub
            shadow-md
            drop-shadow-2xl
            lg:p-2
            rounded-md
            place-items-center
          ">
                <div class="flex justify-center">
                    <div v-if="true">
                        <div class="z-50 flex justify-center items-center">
                            <div class="
                    animate-spin
                    rounded-full
                    md:h-32 md:w-32
                    h-24
                    w-24
                    border-t-2 border-b-2 border-gray-100
                  "></div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <p class="text-md text-white text-center">الرجاء الانتظار</p>
                    <div class="border border-b-2 border-color mx-auto mt-1 w-36"></div>

                    <div class="m-3 relative md:w-96 mx-auto" dir="rtl"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { USER } from '@/constants';
import { useAuthStore } from '@/stores/auth';
import { mapActions } from 'pinia';
export default {
    components: {},
    data() {
        return {
            user: {
                email: ''
            }
        }
    },
    computed: {},

    methods: {
        ...mapActions(useAuthStore, ['login', 'setData', 'getUserFromSession']),
        async loginAction() {
            const user = JSON.parse(localStorage.getItem(USER));
            this.user.email = user.email;
            if (this.user) {
                await this.login(this.user).then((response) => {
                    if (response.data.success) {
                        this.setData(response);
                    }
                }).catch((error) => {
                    console.log(error)
                    this.toast.error('something went wrong')
                })
            }

        },
        async redirection() {
            const isActive = localStorage.getItem("isActive");
            if (isActive) {
                setTimeout(() => {
                    this.$router.push({ name: "home" });
                }, 6000);
            } else {
                await this.$router.push({ name: "failed" });
            }
        },
    },
    async created() {
        await this.loginAction();

    }

};
</script>


<style scoped>
.w-btn {
    width: 290px;
    align-self: right;
}

.btn-color {
    background-color: #E54057;
}

.bg-sub {
    background-color: #181b30;
}
</style>