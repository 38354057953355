<template>
  <div class="mt-2 " dir="ltr">
    <swiper
     
      :grabCursor="true"
      :modules="modules"
      :slidesPerView="'auto'"
      :spaceBetween="20"
      :freeMode="true"
      class="mySwiper"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
    >
      <swiper-slide v-for="(item, index) in items" :key="item.id">
        <SeriesItem :item="item"  :className="className" :index="index" />
      </swiper-slide>
       <!-- Add navigation buttons -->
       <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </swiper>
  </div>
</template>
  <script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"; // Import navigation styles

// import required modules
import { Mousewheel, Keyboard,Navigation } from "swiper";

import SeriesItem from "./SeriesItem.vue";

export default {
  props: ["items", "className"],
  components: {
    Swiper,
    SwiperSlide,
    SeriesItem,
  },
  setup() {
    return {
      modules: [Mousewheel, Keyboard,Navigation],
    };
  },
};
</script>
<style scoped>
.swiper {
  width: 100%;
  height: 400px;
}
@media only screen and (max-width: 960px) {
  .swiper {
    width: 100%;
    height: 190px;
  }
  /* Hide navigation buttons on non-desktop screens */
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 290px !important;
  height: 100%;
  /* Center slide text vertically */

  height: 100%;
}

@media only screen and (max-width: 960px) {
  .swiper-slide {
    text-align: center;
    width: 135px !important;
    height: 100%;
  }


}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: fill !important;
}
.shadow-css{
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
/* Navigation buttons styles */
.swiper-button-prev, .swiper-button-next {
  color: #FFF; /* Change the color to suit your design */
}

.swiper-button-prev, .swiper-button-next {
  font-size: 20px !important;
  width: 80px; /* Width of the arrow button */
  height: 80px; /* Height of the arrow button */
  margin-top: -25px; /* Half of the height to center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: #fff; /* White arrow color */
  border-radius: 50%; /* Circular shape */
 
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}


.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
  cursor: auto;
  pointer-events: none;
}

</style>

