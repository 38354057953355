import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getEposides, getFiltter, getEposide } from "@/Gql/episodes";
import { AUTHKey, AUTHTOKEN } from "@/constants";
export const useEposidesStore = defineStore("eposides", {
    state: () => {
        return {
            eposides: Array,
            meta: Object,
            asset: Object,
            eposideTest:Array,
            loading:false
        }
    },
    getters: {
        eposidesData: (state) => state.eposides,
        eposidesMetaData: (state) => state.meta,
        assetData: (state) => state.asset,
        epo: (state) => state.eposideTest,
        loadingStatus:(state)=>state.loading,
        getError:(state)=>state.error

    },
    actions: {
        async showEposidesData(page = 1, limit = 6) {
                this.eposideTest=this.eposides
            await apolloClient
                .query({
                    query: getEposides,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.eposides=[...res.data.getEposides.data.filter((eposide)=>eposide.published===true)],
                    this.meta = res.data.getEposides.meta
                   
                })
                .catch(error => {
                    this.error = error
                })
                this.eposides.slice().push(this.eposideTest)
        },

        async showEposidesNextData(page = 1, limit = 6) {
                this.loading=true;
            await apolloClient
                .query({
                    query: getEposides,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.eposides.push(...res.data.getEposides.data.filter((eposide)=>eposide.published===true)),
                    this.meta = res.data.getEposides.meta
                   
                })
                .catch(error => {
                    this.error = error
                })
                this.loading=false
        },

        async filtterData(page = 1, limit = 20, text = "",genreid ,type="eposides",order =null,orderBy=null) {
            console.log(page+"page",limit ,text,genreid,type)
            await apolloClient
                .query({
                    query: getFiltter,
                    variables: {
                        page: page, limit: limit, text: text, genreId: genreid,type:"eposides" , order: order, orderBy: orderBy,

                    },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.eposides = res.data.filters.data.filter((eposide)=>eposide.published===true),
                    this.meta = res.data.filters.meta
                })
                .catch(error => {
                    this.error = error
                })
        },

    //  

        async fetchAssets(id) {
            this.asset = null;
            await apolloClient
                .query({
                    query: getEposide,
                    variables: { getEposideId: id },
                    fetchPolicy: "network-only", // Changed from "cache-first" to "network-only"
                    context: {
                        headers: {
                            'token': `${localStorage.getItem(AUTHTOKEN)}`, 
                            'key': `${localStorage.getItem(AUTHKey)}` 
                        }
                    }
        
                }).then(async res => {
                    this.asset = res?.data?.getEposide

                })
                .catch(error => {
                    this.error = error
                })
        },

        //async filters(genre="",text="",orderBy=""){
                    
            //             if(genre!="" && text=="" && orderBy==""){
            //               return this.eposides= await (this.eposides.filter(eposide => eposide.serie.genre.title.indexOf(genre) >= 0));
            //           }
        
            //           if(genre=="" && text!="" && orderBy==""){
            //             return this.eposides= await (this.eposides.filter(eposide => eposide.title.indexOf(text) >= 0));
            //            }
        
            //            if(genre!="" && text!="" && orderBy==""){
            //             let genres=[];
            //               genres= await (this.eposides.filter(eposide => eposide.serie.genre.title.indexOf(genre) >= 0));
            //               console.log(genres)
            //             return this.eposides= await (genres.filter(genre => genre.title.indexOf(text) >= 0));
            //            }
                       
                      
            //     },
      

    }
})