<template>
  <transition>
    <div
      class="carousel-item w-full h-full bg-image absolute top-0 left-0 object-cover z-0 bg-center bg-cover fade-in-image slider"
      :style="{ backgroundImage: `url(${image()})` }"
    >
      <div class="">
        <img
          src="@/assets/shadow.png"
          class="block absolute bg-repeat-x top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2 object-cover h-full md:h-96"
          alt="image"
          style="width: 100%"
          width="100"
          height="100"
        />
      </div>
      <div class="absolute text-right right-0 bottom-8 m-2">
        <p class="text-white md:text-sm text-xs md:w-96 mr-6 z-20 w-128">
          {{ '' }}
        </p>
        <router-link to="/">
          <button
            v-show="false"
            type="button"
            class="btn-bg focus:outline-none rounded-lg text-sm px-3 py-1 mr-6 mb-2 text-white text-center inline-flex items-center md:text-md mt-2 z-20"
          >
            {{ 'Watch Now' }}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mb-1 ml-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
              stroke-width="1.2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </router-link>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      s3: 'https://africatv-systech.s3.af-south-1.amazonaws.com/webapp/sliders/'
    }
  },
  computed: {
    transitionEffect () {
      return this.direction === 'right' ? 'slide-out' : 'slide-in'
    }
  },
  methods: {
    image () {
      return require('@/assets/' + 'slider-placeholder.webp')
    }
  }
}
</script>

<style scoped>
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/* .slide-in-enter-active,
  .slide-in-leave-active,
  .slide-out-enter-active,
  .slide-out-leave-active {
    transition: all 0.7s ease-in-out;
  }
  .slide-in-enter-from {
    transform: translateX(-100%);
  }
  .slide-in-leave-to {
    transform: translateX(100%);
  }
  .slide-out-enter-from {
    transform: translateX(100%);
  }
  .slide-out-leave-to {
    transform: translateX(-100%);
  } */
.btn-bg {
  background-color: #E54057;
}
.fade-in-image {
  animation: fadeIn 1000ms;
  -webkit-animation: fadeIn 1000ms;
  -moz-animation: fadeIn 1000ms;
  -o-animation: fadeIn 1000ms;
  -ms-animation: fadeIn 1000ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
