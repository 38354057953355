import { defineStore } from "pinia";
import { nasna } from "../http-common.js"
import { AUTHKey, AUTHTOKEN, is_subscribed, ISACTIVE, USER } from "@/constants.js";


export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            isLoggedin: Boolean, // check user status 
            user: JSON.parse(localStorage.getItem("user")), // user data to be stored 
            token: "",
            isActive:localStorage.getItem(ISACTIVE)

        }
    },// end of state
    getters: {
        getUser: (state) => state.user,
        isAuthenticated: (state) => state.user != undefined,
        getPhone: (state) => state.phone,
        getISActive:(state)=> state.isActive

    }, //end of getters 
    actions: {
        /***
         * @param Object User 
         * the user object contains {name,email,phone}
         * 
         */
        registerUserAction(user) {
            return new Promise((resolve, reject) => {
                try {
                    let response = nasna.post(`/auth/msisdn/register`,user);
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        },
       
        /**
         * login user for authentication purpose 
         */
        async loginUserAction(data) {
            return new Promise((resolve, reject) => {
                nasna.post('/auth/msisdn/login', data)
                    .then((response) => {

                        resolve(response)
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            resolve(error.response)
                        } else {
                            reject(error);
                        }

                    });

            });

        },
        
      
       
        verifyOtp(otp) {
            let user = JSON.parse(localStorage.getItem(USER));
            let phone = user.phone ? user.phone : user.msisdn;
            const data = {
                msisdn:phone,
                otp:otp
            }
            return new Promise((resolve, reject) => {
                nasna.post('/auth/msisdn/verify', data)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });


        },
        subscribeAction(user) {
            return new Promise((resolve, reject) => {
                try {
                    let response = nasna.post(`/paystack/init`,user);
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        },
        checkSubscriptionStatus(session_id) {
            return new Promise((resolve, reject) => {
                try {
                    let response = nasna.get(`/paystack/verify/${session_id}`);
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        },
        cancelSubscription(user_id) {
            return new Promise((resolve, reject) => {
                try {
                    let response = nasna.get(`/paystack/cancel/all/${user_id}`);
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        },
        async setData(response) {

            if (response.data.success) {
                
                window.localStorage.setItem(USER, JSON.stringify(response.data.user));
                window.localStorage.setItem(AUTHTOKEN, response.data.token);
                window.localStorage.setItem(AUTHKey, response.data.key);
                window.localStorage.setItem(ISACTIVE,  true);

            } else {
                this.isLoggedin=false
                this.wipeUserData(response)
            }

        },


        // eslint-disable-next-line
        wipeUserData(response) {
            window.localStorage.removeItem(USER),
                    window.localStorage.removeItem(AUTHTOKEN),
                    window.localStorage.removeItem(AUTHKey),
                    window.localStorage.removeItem(ISACTIVE)
                    window.localStorage.removeItem(is_subscribed)
                    this.isActive=false

            
            this.isActive=false
        },




    }
})