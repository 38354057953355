<template>
  <div
    :id="`channel-${index}`"
    class="rounded-lg h-full w-full"
    :class="className"
  >
    <router-link :to="`/brows/live/${item.id}`">
      <img
        class="rounded-lg object-fit md:h-72 h-48 w-36 md:w-56"
        :src="getImage(item)"
        width="100"
        height="100"
        :alt="item.title ?? 'live-image'"
        style="object-fit: fill !important"
      />
    </router-link>
    <h5 class="m-2 text-lg font-bold text-center tracking-tight text-white">
      {{ item.host_name }}
    </h5>

    <div class="absolute top-2 right-2 m-1 space-between h-7">
      <div
        class="align-baseline nowrap rounded text-center mt-2 mr-2 py-0.5 text-sm px-4 inline font-normal bg-[#E54057] md:text-md text-white"
      >
        Kai tsaye
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "className", "index"],
  data() {
    return {
      s3: "http://dkmbnaouy4gi3.cloudfront.net/webapp/lives/",
    };
  },
  methods: {
    getImage(item) {
      if (item.cover) {
        return this.s3 + item.cover;
      } else {
        var images = require.context("@/assets/", false, /\.webp$/);
        return images("./" + "placeholder-2.webp");
      }
    },
  },
};
</script>

<style >
</style>