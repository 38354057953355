<template>
  <div :id="`index-${index}`" class="rounded-lg h-full w-full" :class="className">

   
    <div class="absolute right-2 md:right-6 top-1">
      <div class="flex justify-between space-between h-7">
        <div
          class=" nowrap rounded-sm text-center  mt-2 mr-1 text-[11px] px-3 inline font-normal bg-orange-500 md:text-md text-white" style="line-height: 1.2rem;" >
          {{ item.eposides_count + " KASHI" }}
        </div>
      </div>
    </div>
    <router-link :to="`/watch/now/${item.id}`" class="">
      <img ref="seriesImage" class="rounded-lg swiper-lazy w-full h-full object-fit"  :src="defaultImage" :data-src="image(item)" width="100"
        height="100" :alt="item.title" style="object-fit: cover !important" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['item', 'className', 'index'],
  data() {
    return {
      s3: 'https://africatv-systech.s3.af-south-1.amazonaws.com/webapp/series/',
      defaultImage: require('@/assets/placeholder-2.webp'),

      observer: null,
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1, // Adjust as needed
    });

    this.observer.observe(this.$refs.seriesImage);
  },
  methods: {
    formateSyntax(count) {
      if (count == 1) {
        return 'حلقة واحدة'
      } else if (count == 2) {
        return 'حلقتان'
      } else if (count > 2 && count < 11) {
        return `${count} حلقات`
      } else {
        return `${count} حلقة`
      }
    },
    image(item) {
      if (item.image) {
        return this.s3 + item.image
      } else {
        var images = require.context('@/assets/', false, /\.webp$/)
        return images('./' + 'placeholder-1.webp')
      }
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          this.observer.unobserve(img);
        }
      });
    },
  }
}
</script>

<style></style>
