<template>
  <div>
    <nav class="w-full md:h-36 h-16">
      <div
        class="px-4 h-full mx-auto flex md:justify-between justify-center items-center sticky z-40 sm:bg-mobile shadow-menu"
        style=""
      >
        <!-- Logo Here -->
        <div class="logo z-40 mr-2">
          <router-link to="/" class="">
            <img
              src="@/assets/logo-6.png"
              class="ml-3"
              alt="Nasan Logo"
              width="100"
              height="100"
            />
          </router-link>
        </div>

        <div class="md:mr-auto">
          <!-- Menu links here -->
          <ul
            id="menu"
            class="hidden fixed top-0 right-0 px-10 py-16 bottom-20 z-30 md:relative md:flex md:p-0 md:bg-transparent md:flex-row lg:-space-x-1 duration-700 divider-x divide-gray-700"
            dir="ltr"
          >
            <li class="flex justify-start items-center">
              <router-link
                to="/categories"
                class="block py-2 pr-4 lg:text-[20px] text-md pl-3 text-white"
              >
                Duba Ƙungiyoyi
              </router-link>
            </li>
            <hr />

            <hr />
            <li class="flex justify-start items-center">
              <router-link
                to="/brows/programs"
                class="block py-2 pr-4 lg:text-[20px] text-md pl-3 text-white"
              >
                Shirye-shiryen Talabijin
              </router-link>
            </li>
            <hr class="bg-red-400" />

            <li class="flex justify-start items-center">
              <router-link
                to="/brows/channels"
                href="#"
                class="block py-2 pr-4 lg:text-[20px] text-md pl-3 text-white"
              >
                Tashoshi

                <small
                  class="text-white bg-red-600 rounded-sm px-2 py-1.5 text-xs"
                >
                  Kai tsaye
                </small>
              </router-link>
            </li>
            <hr />
          </ul>

          <!-- This is used to open the menu on mobile devices -->
        </div>

        <div class="z-30">
          <div class="flex flex-wrap justify-between items-center">
            <!-- Dropdown menu -->

            <!--end Dropdown menu -->
            <div class="flex justify-center">
              <div class="relative hidden md:block ml-2">
                <!-- This is an example component -->
                <SearchView />
              </div>
            </div>

            <auth-actions />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import AuthActions from "./shared/AuthActions.vue";
import SearchView from "./shared/SearchView.vue";
export default {
  components: { SearchView, AuthActions },

  name: "MobileView",
  data() {
    return {};
  },
  methods: {
    toggleMenu() {
      var menu = document.getElementById("menu");
      menu.classList.toggle("hidden");
      menu.classList.toggle("w-full");
      menu.classList.toggle("h-full");
      menu.classList.toggle("order-btn");
    },
  },
};
</script>

<style>
/* .bg-mobile{
    background-color: rgba(0, 0, 0, 0.1);
  } */

input[type="text"]::placeholder {
  text-align: right;
}
focus:input[type="text"] {
  outline: none;
  border: none;
  outline-width: 0;
}
.shadow-menu {
  background: linear-gradient(
    to top,
    rgb(23, 22, 20, 0) 0%,
    rgb(23, 22, 20, 100)
  );
}
</style>
