<template>
  <div class="carsouel h-full absolute top-0 w-full">
    <!-- he -->
    <div class="carsoule-inner relative h-[28rem] overflow-hidden md:h-[80dvh]">
      <carousel-indicators
        v-if="indicators"
        :total="slidersData.length"
        :current-index="currentSlide"
        @switch="switchSlide($event)"
      ></carousel-indicators>
      <div v-show="!loaded">
        <carousel-item
          v-for="(slide, index) in slidersData"
          :slide="slide"
          :key="`item-${index}`"
          :current-slide="currentSlide"
          :index="index"
          :direction="direction"
          @mouseenter="stopSlideTimer"
          @mouseout="startSlideTimer"
          @swipeDirection="swipeDirection"
        ></carousel-item>
      </div>
      <div v-show="loaded">
        <CarosuelLoader />
      </div>
    </div>
  </div>
</template>

<script>
import CarouselItem from './CarouselItem.vue'
import { mapState, mapActions } from 'pinia'
import CarouselIndicators from './CarouselIndicators.vue'
import { useSlidersStore } from '@/stores/sliders'
import CarosuelLoader from './CarosuelLoader.vue'
export default {
  props: {
    controls: {
      type: Boolean,
      default: false
    },
    indicators: {
      type: Boolean,
      default: false
    },
    interval: {
      type: Number,
      default: 5000
    }
  },
  components: { CarouselItem, CarouselIndicators, CarosuelLoader },
  data: () => ({
    currentSlide: 0,
    slideInterval: null,
    direction: 'right',
    loaded: false
  }),

  computed: {
    ...mapState(useSlidersStore, ['slidersData'])
  },
  methods: {
    ...mapActions(useSlidersStore, ['showSlidersData']),
    setCurrentSlide (index) {
      this.currentSlide = index
    },
    prev (step = -1) {
      const index =
        this.currentSlide > 0
          ? this.currentSlide + step
          : this.slidersData.length - 1
      this.setCurrentSlide(index)
      this.direction = 'left'
      this.startSlideTimer()
    },
    _next (step = 1) {
      const index =
        this.currentSlide < this.slidersData.length - 1
          ? this.currentSlide + step
          : 0
      this.setCurrentSlide(index)
      this.direction = 'right'
    },
    next (step = 1) {
      this._next(step)
      this.startSlideTimer()
    },
    startSlideTimer () {
      this.stopSlideTimer()
      this.slideInterval = setInterval(() => {
        this._next()
      }, 6000)
    },
    stopSlideTimer () {
      clearInterval(this.slideInterval)
    },
    switchSlide (index) {
      const step = index - this.currentSlide
      if (step > 0) {
        this.next(step)
      } else {
        this.prev(step)
      }
    }, //end of slider switch
    swipeDirection (event) {
      if (event.changedTouches[0].screenX > 180) {
        this.next()
      } else {
        this.prev()
      }
    }
  },
  mounted () {
    this.startSlideTimer()
  },
  beforeUnmount () {
    this.stopSlideTimer()
  },
  async created () {
    this.loaded = true
    try {
      await this.showSlidersData()
      this.loaded = false
    } catch (error) {
      console.log(this.error)
      this.loaded = false
    }
  }
}
</script>

<style scoped>
.carousel {
  display: flex;
  justify-content: center;
  border: none;
}
.carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
}
</style>
