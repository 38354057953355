<template>
  <div class="flex justify-center rounded-lg mx-2 mt-12">
    <div class="">
      <div
        class="container mx-auto px-4 p-1 bg-sub shadow-md drop-shadow-2xl lg:p-2 rounded-md place-items-center"
      >
        <div class="flex justify-center">
          <div v-if="true">
            <div class="z-50 flex justify-center items-center">
              <div
                class="animate-spin rounded-full md:h-32 md:w-32 h-24 w-24 border-t-2 border-b-2 border-gray-100"
              ></div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <p class="text-md text-white text-center"> 	Jira...</p>
          <div class="border border-b-2 border-color mx-auto mt-1 w-36"></div>

          <div class="m-3 relative md:w-96 mx-auto" dir="rtl"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { is_subscribed } from "@/constants";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      toast: useToast(),
    };
  },
  components: {},
  computed: {},

  methods: {
    ...mapActions(useAuthStore, ["checkSubscriptionStatus", "setData"]),
    async redirection() {
      setTimeout(() => {
        console.log("Test CMP Mounted")
      }, 6000);

      await this.checkSubscriptionStatus(this.$route.query.reference).then(
        (response) => {
          if (response.data.success) {
            this.toast.success("An Kammala Biyan Kuɗin Shiga Da Nasara");

            localStorage.setItem(is_subscribed, response.data.is_subscribed);
            setTimeout(() => {
              this.$router.push("/");
            }, 3000);
          } else {
            this.$router.push("/user/subscriptions");
          }
        }
      );
    },
  },
  async created() {
    await this.redirection();
  },
};
</script>

<style scoped>
.w-btn {
  width: 290px;
  align-self: right;
}

.bg-sub {
  background-color: #181b30;
}
</style>
