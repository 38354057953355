<template>
    <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mb-1 ml-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>