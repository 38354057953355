import gql from "graphql-tag";

export const getSliders=gql`
query Query($page: Int!, $limit: Int!) {
getSliders(page: $page, limit: $limit) {
data {
    id
      title
      image
      serie_id
      desc
      published
}
}
}
`