<template>
  <div class="flex place-items-center justify-center items-center flex-col">
    <div class="md:justify-center md:mx-auto mb-6">
      <div class="relative">
        <input
          type="text"
          placeholder="Neman Shirye-shiryen Talabijin"
          class="py-1 px-4 w-60 md:w-96 text-white rounded-md border-color sm:text-lg focus:ring-0 focus:border-color xs-360:mr-8 bg-sub"
          v-model="text"
          dir="ltr"
        />
        <svg
          @click="handleChange"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 absolute right-12 top-1.5 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#E54057"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
    </div>
    <!-- order by -->
    <div class="mb-4 md:mr-0">
      <div class="grid grid-cols-2 justify-center items-center w-full gap-x-2 md:gap-x-7">
        <div class="">
          <select
            id="order-by-one"
            class="bg-[#2d2b27] border-color border-2 text-white text-sm rounded-md focus:ring-0 focus:border-[#E54057] block text-left py-1 px-2 select-2 md:w-48 "
            page
            @change="handleChange()"
            v-model="orderBy"
          >
            <option dir="ltr" disabled :value="test" selected>oda</option>

            <option dir="ltr" :value="desc">Jerin harufa</option>

            <option dir="ltr" :value="view_count">Mafi yawan kallo</option>
            <option dir="ltr" :value="createdAt">Sabo</option>
          </select>
        </div>

        <div class="" v-show="genresData">
          <select
            id="order-by"
            class="bg-[#2d2b27] border-color border-2 text-white text-sm rounded-md focus:ring-0 focus:border-[#E54057] block text-left py-1 px-2"
            @change="handleChange()"
            v-model="genreId"
          >
            <option dir="ltr" disabled :value="test">Ɓangare</option>
            <option dir="ltr" :value="all">All</option>

            <option
              dir="ltr"
              v-for="genre in genresData"
              :key="genre.id"
              :value="`${genre.id}`"
              class="text-center"
            >
              {{ genre.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGenresStore } from "@/stores/genres";
import { mapActions, mapState } from "pinia";
export default {
  data() {
    return {
      text: "",
      test: " ",
      all: "all",
      genreId: " ",
      orderBy: "createdAt",
      view_count: "view_count",
      desc: "",
      order: null,
      createdAt: "createdAt",
      type: "",
    };
  },
  async created() {
    await this.showGenresData();
  },
  computed: {
    ...mapState(useGenresStore, ["genresData"]),
  },

  methods: {
    ...mapActions(useGenresStore, ["showGenresData"]),
    handleChange() {
      this.$emit(
        "customChange",
        this.text,
        this.genreId,
        this.type,
        this.order,
        this.orderBy
      );
    },
  },
};
</script>

<style scoped>
.bg-sub {
  background-color: black;
}
input[type="text"]::placeholder {
  text-align: center;
}
</style>
