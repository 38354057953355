import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getFiltter, getSeries, getSerie, getHomeSeries } from "@/Gql/series";
import { getMostWatchedEps } from "@/Gql/home";
export const useSerieStore = defineStore("serie", {
    state: () => {
        return {
            serie: Object,
            series: Array,
            genre: Object,
            eposides: Array,
            eposide: Array,
            meta: Object,
            loaded: false,
            mostWatched: Array,


        }
    },
    getters: {

        serieData: (state) => state.serie,
        seriesData: (state) => state.series,
        genreData: (state) => state.genre,
        seriesMetaData: (state) => state.meta,
        firstEposide: (state) => state.eposide,
        loadingState: (state) => state.loaded,
        getMostWatched: (state) => state.mostWatched


    },
    actions: {
        async filtterData(page = 1, limit = 6, text = "", genreid, type = "series", order = null, orderBy = null) {

            await apolloClient
                .query({
                    query: getFiltter,
                    variables: {
                        page: page, limit: limit, text: text, genreId: genreid, type: type, order: order, orderBy: orderBy,

                    },
                    fetchPolicy: "cache-first",

                }).then(async res => {

                    this.series = res.data.filters.data,
                        this.meta = res.data.filters.meta
                })
                .catch(error => {
                    this.error = error

                })
        },
        async ShowSeriesData(page = 1, limit = 10) {
            await apolloClient
                .query({
                    query: getSeries,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",
                    notifyOnNetworkStatusChange: true,
                }).then(async res => {
                    this.series = [...res.data.getSeries.data.filter((serie) => serie.published === true)],
                        this.meta = res.data.getSeries.meta
                })
                .catch(error => {
                    this.error = error
                })
        },
        async showMostWatched(limit = 6) {
            await apolloClient
                .query({
                    query: getMostWatchedEps,
                    variables: {
                        input: {
                            limit: limit
                        }
                    },
                    fetchPolicy: "cache-first",
                    notifyOnNetworkStatusChange: true,
                }).then(async res => {
                    this.mostWatched = [...res.data.getMostWatchedEposides.data.filter((eposide) => eposide.published === true)]

                })
                .catch(error => {
                    this.error = error
                })
        },
        async showHomeSeries(page = 1, limit = 6) {
            await apolloClient
                .query({
                    query: getHomeSeries,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",
                    notifyOnNetworkStatusChange: true,
                }).then(async res => {
                    this.series = [...res.data.newSeries.data.filter((serie) => serie.published === true)],
                        this.meta = res.data.newSeries.meta
                })
                .catch(error => {
                    this.error = error
                })
        },

        async ShowSeriesNextData(page = 1, limit = 10) {
            this.loaded = true;
            await apolloClient
                .query({
                    query: getSeries,
                    variables: { page: page, limit: limit },
                    fetchPolicy: "cache-first",
                    notifyOnNetworkStatusChange: true,
                }).then(async res => {
                    this.series.push(...res.data.getSeries.data.filter((serie) => serie.published === true)),
                        this.meta = res.data.getSeries.meta
                    this.loaded = false
                })
                .catch(error => {
                    this.error = error
                    this.loaded = false
                    console.log(this.error)
                })

        },
        async ShowSerieData(getSerieId) {
            this.serie = null;
            await apolloClient
                .query({
                    query: getSerie,
                    variables: { getSerieId: getSerieId },
                    fetchPolicy: "cache-first",
                    notifyOnNetworkStatusChange: true,
                }).then(async res => {
                    this.serie = res.data.getSerie
                    this.eposide = res.data.getSerie.eposides[0] ? res.data.getSerie.eposides[0] : []
                    this.eposides = res.data.getSerie.eposides
                })
                .catch(error => {
                    this.error = error
                })

        },

    }
})