import { defineStore } from "pinia";
import { HTTP}  from "../http-common.js"

// the portal id
export const usePortalStore = defineStore("portal", {
    state: () => {
        return {
            portal: Object,
            nansnaId: "cl792gm9b0039a3qobvch51la"
        }//new portal
    },
    getters: {
        portalData: (state) => state.portal
    },
    actions: {
        async showPortalData(){
            await HTTP.get(`/portals/${this.nansnaId}`)
            .then(async res => {
                this.portal = res.data
            })
            .catch(error => {
                this.error = error
            })
        }
    }
})