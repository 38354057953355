<template>
  <div class="my-2 animate-in slide-in-from-right duration-700">
    <swiper ref="mySwiper" :grabCursor="true" :freeMode="true" :modules="modules" :keyboard="{
      enabled: true,
    }" :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }" :slidesPerView="'auto'" :spaceBetween="20" :centeredSlides="false">
      <swiper-slide v-for="(item, index) in items" :key="item.id" >
        <card-series :item="item" :index="index" :image="image" :currentPlaying="currentPlaying" />
      </swiper-slide>
      <!-- Add navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation"; // Import navigation styles

// import required modules
import { Mousewheel, Keyboard, Navigation } from "swiper";
import CardSeries from "./CardSeries.vue";

export default {
  props: ["items", 'image', 'currentPlaying'],
  components: {
    Swiper,
    SwiperSlide,
    CardSeries,
  },
  data() {
    return {
      swiperInstance: null, // Store the Swiper instance
    };
  },
  setup() {
    return {
      modules: [Mousewheel, Keyboard, Navigation],
    };
  },
  watch: {
    currentPlaying(newVal) {
      if (this.swiperInstance && newVal !== null) {
        this.$nextTick(() => {
          this.swiperInstance.slideTo(newVal);
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperInstance = this.$refs.mySwiper.swiper;
        const index = this.items.findIndex(item => item.id === this.currentPlaying);
      if (this.swiperInstance&&index !== -1) {
        this.swiperInstance.slideTo(index);
      }
    });
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 308px;
}

@media only screen and (max-width: 960px) {
  .swiper {
    width: 100%;
    height: 168px;
  }

  /* Hide navigation buttons on non-desktop screens */
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 480px !important;
  height: 100%;
  height: 100%;
}

@media only screen and (max-width: 960px) {
  .swiper-slide {
    text-align: center;
    width: 240px !important;
    height: 100%;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: fill !important;
}

/* Navigation buttons styles */
.swiper-button-prev,
.swiper-button-next {
  color: #FFF;
  /* Change the color to suit your design */
}

/* Navigation buttons styles with overlay */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 45%;
  z-index: 99999;
  font-size: 20px !important;
  width: 80px;
  /* Width of the arrow button */
  height: 80px;
  /* Height of the arrow button */
  margin-top: -25px;
  /* Half of the height to center vertically */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  color: #fff;
  /* White arrow color */
  border-radius: 50%;
  /* Circular shape */

}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
  cursor: auto;
  pointer-events: none;
}
</style>
