<template>

    <div class="" dir="rtl">

        <div class="max-w-4xl mx-auto p-4 text-gray-300" dir="rtl">

            <h1 class="text-3xl font-bold text-center mb-6">شروط وأحكام استخدام خدمة Nasna لبث الفيديو عند الطلب الرقمي
            </h1>

            <section class="mb-5">
                <p>مرحبًا بك في Nasna، خدمة بث الفيديو عند الطلب الرقمي. تحكم هذه الشروط والأحكام ("الشروط") في وصولك
                    إلى
                    خدمة Nasna واستخدامها، بما في ذلك أي محتوى ووظائف وخدمات تُقدم على أو من خلال www.watch.nasna.tv
                    (الخدمة)، التي تديرها مشاريع سيستك. من خلال الوصول إلى الخدمة أو استخدامها، فإنك توافق على الالتزام
                    بهذه
                    الشروط. إذا لم توافق على هذه الشروط، فلا يمكنك الوصول إلى الخدمة أو استخدامها.</p>
            </section>

            <section class="mb-5">
                <h2 class="text-2xl font-semibold mb-3">قبول الشروط</h2>
                <p>من خلال الوصول إلى الخدمة أو استخدامها، فإنك توافق على هذه الشروط، بما في ذلك سياسة الخصوصية الخاصة
                    بنا.
                    إذا لم توافق على هذه الشروط، فيرجى عدم الوصول إلى الخدمة أو استخدامها.</p>
            </section>

            <section class="mb-5">
                <h2 class="text-2xl font-semibold mb-3">الاشتراك والدفع</h2>
                <p>قد يتطلب الوصول إلى خدمة Nasna الاشتراك والدفع. من خلال الاشتراك في الخدمة، فإنك توافق على دفع جميع
                    الرسوم والرسوم المطبقة. يمكن إجراء الدفع عبر أرقام الهواتف، وبطاقات الائتمان/الخصم، وGoogle Pay، أو
                    Apple Pay. من خلال تقديم معلومات الدفع، فإنك تفوض Nasna لفرض رسوم الاشتراك على طريقة الدفع التي
                    اخترتها
                    في وقت الشراء أو التجديد.</p>
            </section>
            <section class="mb-5">
                <h2 class="text-2xl font-semibold mb-3">حسابات المستخدم </h2>
                <p>

                    قد يُطلب منك إنشاء حساب للوصول إلى ميزات معينة من الخدمة. فإنك توافق على تقديم معلومات دقيقة وحديثة
                    وكاملة خلال عملية التسجيل وتحديث هذه المعلومات للحفاظ على دقتها وحديثها واكتمالها. أنت مسؤول عن
                    الحفاظ على سرية حسابك وكلمة المرور، وتوافق على قبول المسؤولية عن جميع الأنشطة التي تحدث تحت حسابك أو
                    كلمة المرور. </p>
            </section>
            <section class="mb-5">
                <h2 class="text-2xl font-semibold mb-3">جمع واستخدام المعلومات </h2>
                <p>قد تقوم Nasna بجمع وتخزين المعلومات الشخصية، بما في ذلك على سبيل المثال لا الحصر عناوين البريد
                    الإلكتروني، وأرقام الهواتف، والمواقع، وسلوك المستخدم على الموقع الإلكتروني. من خلال استخدام الخدمة،
                    فإنك توافق على جمع وتخزين واستخدام هذه المعلومات وفقًا لسياسة الخصوصية الخاصة بنا. </p>
            </section>
            <section class="mb-5">
                <h2 class="text-2xl font-semibold mb-3"> </h2>
                <p></p>
            </section>
            <section class="mb-5">
                <h2 class="text-2xl font-semibold mb-3"> </h2>
                <p></p>
            </section>


            <!-- Add other sections following the same pattern -->

            <section class="mb-5">
                <h2 class="text-2xl font-semibold mb-3">اتصل بنا</h2>
                <p>إذا كانت لديك أي أسئلة حول هذه الشروط، يرجى الاتصال بنا على info@systechsd.net</p>
            </section>

            <section>
                <p>من خلال الوصول إلى الخدمة أو استخدامها، فإنك تقر بأنك قرأت وفهمت وتوافق على الالتزام بهذه الشروط
                    والأحكام.</p>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsAndConditions',

};
</script>

<style scoped></style>