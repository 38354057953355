<template>
  <footer v-if="$route.meta.footer"
    class="h-full md:h-48 relative px-1 md:p-2 content-center custom-border "
    style="background-color: #171614; "
  >
  <div class="md:hidden">
    <mobile-footer/>
  </div>
   <div class="hidden md:block">
    <desktop-footer/>
   </div>
  
  </footer>
</template>

<script>
import DesktopFooter from './DesktopFooter.vue';
import MobileFooter from './MobileFooter.vue';
export default { components: { MobileFooter, DesktopFooter } ,

};
</script>

<style scoped>
.m-right {
  margin-right: 30%;
}

footer {
  clear: both;
  position: relative;
}
.text-xss {
  font-size: 9px;
}
.custom-border{
  border-top-width: 1px;
  border-color: gray;
}
</style>
