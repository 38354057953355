<template>
  <div id="page-container" class="scroll">

    <!-- <PreloaderView /> -->
    <div  class="md:pb-56 pb-24">
      <router-view :key="$route.fullPath" />
    </div>
    <div
    v-show="$route.meta.footer"
      id="footer"
      class="fixed md:absolute bottom-0 left-0 w-full md:h-48 h-20 z-50"
    >
      <footer-view />
    </div>
  </div>
</template>
<script>
import FooterView from './components/shared/FooterView.vue'
// import PreloaderView from './components/shared/PreloaderView.vue'

export default {
  components: {
    FooterView,
    // PreloaderView
  },
  data () {
    return {
      currentTime: 0,
      show: false,
      registration: null,
      isRefresh: false,
      refreshing: false
    }
  },

  mounted () {},



  created() {
    document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, {
      once: true,
    });
   
  },
  methods: {
    closeUpdata () {
      this.isRefresh = false
    },
    appUpdateUI (e) {
      this.registration = e.detail
      this.isRefresh = true
    },
    update() {
      this.isRefresh = false;

      if (this.registration || this.registration.waiting !=null) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
      
        this.forceUpdate();
    },
    forceUpdate(){
      window.location.reload(true);
    }
  },
};
</script>
<style>
.text-color {
  color: #E54057;
}


.border-color {
  border-color: #E54057 !important;
}
.order-btn {
  background-color: #191a2c;
}
.card-bg {
  background-color: #171614;
}
.btn-color-orange {
  background-color: #d5573b;
}
#page-container {
  position: relative;
  min-height: 100vh;
}


*:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* input.form-control {
    text-align: center;
    height: 60px;
  } */
</style>
