<template>
  <div class="mb-4">
    <HomeLayout :data="false"> </HomeLayout>
    <div class="mt-8 mb-5">
      <spinner-view :loading="false" />
      <p class="text-white text-start font-bold text-xl md:mr-14 ml-2" dir="ltr">
          Search results for : <strong class="text-color"> {{ $route.params.query }}</strong>
      </p>
    </div>
    <div v-show="searchSeriesData">
      <filtter-view @customChange="search" />
    </div>
    <div class=" mx-auto md:px-8 px-4">
      <div
        class="grid grid-cols-2 sm:grid-cols-3 xs-800:grid-cols-4 md:grid-cols-5 ml-4 justify-items-center gap-3 my-4"
        dir="rtl"
        v-if="searchSeriesData.length > 0"
      >
        <div class="relative" v-for="item in searchSeriesData" :key="item.id">
          <SerieItems :item="item" />
        </div>
      </div>
      <div v-else>
        <h1
          v-if="!getStatus | loaded"
          class="font-bold text-xl text-center text-gray-100"
        >
          <div
            class="flex justify-center animate-bounce duration-700 temporary-bounce"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="0.8"
              stroke="#E54057"
              class="w-36 h-36"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
          </div>
            No results found.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import HomeLayout from '@/components/HomeLayout.vue'
import FiltterView from './FiltterView.vue'
import SerieItems from '@/components/cards/SerieItems.vue'
import { useSearchStore } from '@/stores/Search'
import { mapState, mapActions } from 'pinia'
import SpinnerView from '@/components/SpinnerView.vue'
export default {
  components: { HomeLayout, FiltterView, SerieItems, SpinnerView },
  data () {
    return {
      loaded: false,
      loaderState: false,
      loadderState: false,

      page: 1,
      limit: 10
    }
  },
  computed: {
    ...mapState(useSearchStore, ['searchSeriesData', 'getStatus'])
  },
  methods: {
    ...mapActions(useSearchStore, ['filtterData']),
    async search (text, genreid, type = 'series', order = null, orderBy = null) {
      this.loaded = false
      try {
        await this.filtterData(
          this.page,
          this.limit,
          text,
          genreid,
          type,
          order,
          orderBy
        )
        this.loaded = true
      } catch (error) {
        console.error('Failed to videos  data', error)
      }
    },
    async prev () {
      this.loaderState = true

      try {
        this.page--
        await this.ShowSeriesData(this.page, this.limit)
        this.loaderState = false
      } catch (error) {
        console.error('Failed to videos  data', error)
      }
    },
    async next () {
      this.loaderState = true
      try {
        this.page++
        await this.ShowSeriesData(this.page, this.limit)
        this.loaderState = false
      } catch (error) {
        console.error('Failed to videos  data', error)
      }
    },
    async pg (page = 2) {
      this.loaderState = true

      try {
        await this.ShowSeriesData(page, this.limit)
        this.loaderState = false
      } catch (error) {
        console.error('Failed to videos  data', error)
      }
    }
  }
}
</script>

<style scoped>
.bg-sub {
  background-color: black;
}
select {
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 1em;
}
.temporary-bounce {
  -webkit-animation-iteration-count: 5;
  animation-iteration-count: 5;
}
</style>
