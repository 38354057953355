<template>
  <div class="md:hidden mt-2">
    <search-mobile :show="show" @close="close" />
    <pwa-install-prompt/>
    <nav class="footer_nav footer_nav--icons" dir="ltr">
      <ul class="flex justify-between nowrap">
        <li>
          <router-link to="/">
            <svg class="icon icon-home" viewBox="0 0 24 24" width="26" height="26">
              <path
                fill="currentColor"
                d="M21.6 8.2l-9-7c-0.4-0.3-0.9-0.3-1.2 0l-9 7c-0.3 0.2-0.4 0.5-0.4 0.8v11c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-11c0-0.3-0.1-0.6-0.4-0.8zM14 21h-4v-8h4v8zM20 20c0 0.6-0.4 1-1 1h-3v-9c0-0.6-0.4-1-1-1h-6c-0.6 0-1 0.4-1 1v9h-3c-0.6 0-1-0.4-1-1v-10.5l8-6.2 8 6.2v10.5z"
              ></path>
            </svg>
            <span>Gida</span>
          </router-link>
        </li>

        <li>
          <router-link to="/brows/programs">
            <svg
              class="icon icon-series"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <rect x="2" y="7" width="20" height="15" rx="2" ry="2"></rect>
              <polyline points="17 2 12 7 7 2"></polyline>
            </svg>
            <span> Shirye-shiryen </span>
          </router-link>
        </li>
        <li>
          <a class="cursor" @click="show = true" href="#">
            <svg
              @click="show = true"
              class="icon icon-search cursor"
              viewBox="0 0 24 24"
              width="26"
              height="26"
            >
              <path
                fill="currentColor"
                d="M21.7 20.3l-3.7-3.7c1.2-1.5 2-3.5 2-5.6 0-5-4-9-9-9s-9 4-9 9c0 5 4 9 9 9 2.1 0 4.1-0.7 5.6-2l3.7 3.7c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4zM4 11c0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.9-0.8 3.7-2 4.9 0 0 0 0 0 0s0 0 0 0c-1.3 1.3-3 2-4.9 2-4 0.1-7.1-3-7.1-6.9z"
              ></path>
            </svg>
            <span>Search</span>
          </a>
        </li>
        <li v-if="isActive">
          <router-link to="/confirm/unsubscribe">
            <svg class="icon icon-login" viewBox="0 0 24 24" width="26" height="26">
              <g fill="currentColor">
                <path
                  d="M16 14h-8c-2.8 0-5 2.2-5 5v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-2.8-2.2-5-5-5z"
                ></path>
                <path
                  d="M12 12c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zM12 4c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
                ></path>
              </g>
            </svg>
            <span>Asusuna</span>
          </router-link>
        </li>
        <li v-else @click="$router.push('/user/subscribe')">
          <a href="">
            <svg
              @click="$router.push('/user/subscribe')"
              class="icon icon-login cursor-pointer"
              viewBox="0 0 24 24"
              width="26"
              height="26"
            >
              <g fill="#FFF">
                <path
                  d="M16 14h-8c-2.8 0-5 2.2-5 5v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-2.8-2.2-5-5-5z"
                ></path>
                <path
                  d="M12 12c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zM12 4c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
                ></path>
              </g>
            </svg>
            <span>Login</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import SearchMobile from "./SearchMobile.vue";
import { ISACTIVE } from "@/constants";
import { useToast } from "vue-toastification";
import PwaInstallPrompt from '../PwaInstallPrompt.vue';

export default {
  components: { SearchMobile, PwaInstallPrompt },
  data() {
    return {
      show: false,
      scrolledToBottom: false,
      bottom: "bottom:105px !important",
      top: "bottom:30px !important",
      user: false,
      isActive: false,
      isDone: false,
      toast: useToast(),
    };
  },
  mounted() {
    this.checkIsActiveAction();
  },
  methods: {
    close() {
      return (this.show = false);
    },
    redirectAction() {
      this.$router.push("/user/subscriptions");
    },
    async checkIsActiveAction() {
      return (this.isActive = localStorage.getItem(ISACTIVE));
    },
  }, // monthly starting form 1.5 Dollar //6 months  save 10% yearly save 12%
  redirectUser(response) {
    if (response.data.success) {
      this.window.location.href = response.data.url;
    }
  },
};
</script>

<style>
.footer_nav {
  position: sticky;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 320px;
  max-width: 620px;
  height: 55px;
  border-radius: 30px;
  margin: 0 auto;
}

.footer_nav--icons ul {
  margin: 4px;
  margin-top: 0px;
}

.footer_nav--icons ul li a {
  font-size: 11px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #ffffff;

  vertical-align: middle;
  display: flex;
  align-items: center;
  border-radius: 3em;
  padding: 0.75em 1.25em;
  transition: 0.6s ease-in-out;
}

.footer_nav--icons ul li a span {
  display: inline-block;
  overflow: hidden;
  max-width: 0;
  opacity: 0;
  font-size: 10px;
  padding-left: 0.5em;
  transform: translate3d(-0.5em, 0, 0);
  transition: opacity 0.6s, max-width 0.6s, transform 0.6s;
  transition-timing-function: ease-in-out;
}

.footer_nav--icons ul li a:hover,
.footer_nav--icons ul li a:active {
  color: #fff;
  background-color: #e54057;
}

.footer_nav--icons ul li a:hover span,
.footer_nav--icons ul li a:active span {
  opacity: 1;
  font-weight: 700;
  font-size: 13px;
  margin-top: 3px;
  max-width: 55px;
  margin-left: 8px;
  margin-right: 3px;
  transform: translate3d(0, 0, 0);
}

.cursor {
  cursor: pointer;
}
</style>
