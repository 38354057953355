<template>
  <div class="flex mt-2" v-show="!loaded">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0  24 24"
      stroke-width="1.5"
      stroke="#E54057"
      class="w-6 h-6 mr-1 mt-1"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
      />
    </svg>

    <router-link to="/brows/channels" class="text-white text-lg md:text-[30px]">
      Tashoshi kai tsaye
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["loaded"],
};
</script>

<style></style>
