<template>
  <div class="mx-auto mb-4">
    <HomeLayout :data="false"> </HomeLayout>
    <div class="mt-8 mb-5">
      <p class="text-white text-center font-bold text-xl">
        Shirye-shiryen Talabijin
      </p>
      <div class="border border-b-2 border-color w-40 mx-auto"></div>
    </div>
    <!-- end of title -->
    <filtter-view @customChange="programs" />
    <div class="mx-auto px-4">
      <div
      
        class="grid grid-cols-2 sm:grid-cols-3 xs-800:grid-cols-4 md:grid-cols-5 mr-3 items-center gap-3"
        dir="ltr"
      >
        <div
          class="relative animate-in slide-in-from-bottom-72 duration-700"
          v-for="item in seriesData"
          :key="item.id"
        >
          <SerieItems :item="item" />
        </div>
      </div>
      <div class="absloute bottom-6 hidden">
        <spinner-view :loading="loadingState" />
      </div>
    </div>
   
  </div>
</template>

<script>
import HomeLayout from '@/components/HomeLayout.vue'
import FiltterView from './FiltterView.vue'
import { useSerieStore } from '@/stores/serie'
import { mapState, mapActions } from 'pinia'
import SerieItems from '@/components/cards/SerieItems.vue'
import SpinnerView from '@/components/SpinnerView.vue'
export default {
  components: {
    HomeLayout,
    FiltterView,
    SerieItems,
    SpinnerView,

  },
  data () {
    return {
      loaded: false,
      loaderState: false,
      page: 1,
      limit: 15
    }
  },
  mounted () {
    addEventListener('scroll', this.handleScroll, true)
  },
  computed: {
    ...mapState(useSerieStore, ['seriesData', 'seriesMetaData', 'loadingState'])
  },

  methods: {
    ...mapActions(useSerieStore, [
      'ShowSeriesData',
      'ShowSeriesNextData',
      'filtterData'
    ]),

    handleScroll () {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 50
        if (
          bottomOfWindow &&
          this.seriesMetaData.next_page_url != null &&
          navigator.onLine
        ) {
          this.page++
          this.backTop = false
          this.ShowSeriesNextData(this.page, this.limit)
        } else {
          return (this.backTop = true)
        }
      }
    },

    async programs (
      text,
      genreid,
      type = 'series',
      order = null,
      orderBy = null
    ) {
      this.loaded = false
      if (genreid == 'all') {
        this.loaded = false
        try {
          await this.ShowSeriesData(this.page, this.limit)
          this.loaded = true
        } catch (error) {
          this.loaded = true
          console.error('Failed to videos  data', error)
        }
      } else {
        try {
          await this.filtterData(
            this.page,
            this.limit,
            text,
            genreid,
            type,
            order,
            orderBy
          )
          this.loaded = true
        } catch (error) {
          console.error('Failed to videos  data', error)
        }
      }
    }
  },
  async created () {
    this.loaded = false
    try {
      await this.ShowSeriesData(this.page, this.limit)
      this.loaded = true
    } catch (error) {
      this.loaded = true
      console.error('Failed to videos  data', error)
    }
  }
}
</script>

<style scoped>
.bg-sub {
  background-color: black;
}
select {
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 1em;
}
</style>
