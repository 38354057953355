<template>
    <div class="mt-16">
        <div class="my-6 max-w-4xl mx-auto p-4 text-gray-300">
            <button @click="showLang"
                class="border border-gray-400  rounded-md px-3 py-2 inline-flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF"
                    class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                </svg>
                <template v-if="ar">
                    عربي
                </template>
                <template v-else>
                    En
                </template>
            </button>

        </div>
        <terms-arabic v-if="ar" />
    </div>
</template>

<script>
import TermsArabic from '@/components/site/TermsArabic.vue'
export default {
    components: { TermsArabic },
    data() {
        return {
            ar: true
        }
    },
    methods: {
        showLang() {
            return this.ar = !this.ar;
        }
    }
}
</script>

<style></style>