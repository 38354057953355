import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getFiltter, search } from "@/Gql/search";
export const useSearchStore = defineStore("search", {
    state: () => {
        return {

            series: Array,
            meta: Object,
            loading:Boolean

        }
    },
    getters: {
        searchSeriesData: (state) => state.series,
        searhseriesMetaData: (state) => state.meta,
        getStatus:(state)=>state.loading
    },
    actions: {
        async filtterData(page = 1, limit = 6, text = "",genreid ,type = "series",order = null,orderBy=null) {
               
            await apolloClient
                .query({
                    query: getFiltter,
                    variables: {
                        page: page, limit: limit, text: text, genreId: genreid,type: type , order: order, orderBy: orderBy,

                    },


                }).then(async res => {
                 
                      this.series = res.data.filters.data,
                        this.meta = res.data.filters.meta
                })
                .catch(error => {
                    this.error = error
                    console.log(this.error)
                })
        },
        async ShowSearchData(page = 1, limit = 10,text=""){
            this.series=[];
            this.meta=null;
            this.loading=true;
             return new Promise( (resolve) => {
             apolloClient
                .query({
                    query: search,
                       variables: {
                        page: page, limit: limit, text: text,
                       
                    },

                }).then(async response => {
                    this.series = response.data.search.data,
                    this.meta = response.data.search.meta,
                        this.loading=false
                    resolve(response)
                })
            
            })
               
        },

    }
})