<template>
  <div
    class="relative  h-52 md:w-56 md:h-72 shadow-lg mx-2 w-full bg-shimmer duration-400 animate rounded-md shimmer"
  >
    <img
      class="rounded-lg object-cover w-full h-full shadow-lg opacity-0"
      src="@/assets/placeholder-2.webp"
      width="100"
      height="100"
      alt="loader"
      style=""
      loading="lazy"
    />
    <div class="absolute right-5 md:right-6 top-2" v-show="show">
      <div class="flex justify-between space-between h-5">
        <div
          class="align-baseline nowrap rounded text-center py-0.3 text-sm px-1 w-16 inline font-normal bg-slate-300 md:text-md text-white"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderView',
  props: {
    item: {
      type: Number
    },
    show: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

/* .animate {
   animation : shimmer 2s infinite;
   background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
   background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
} */
.bg-shimmer {
  background: gray;
}
.shimmer {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
</style>
