
export const limit = function limitDigit(event) {
    if (event.target.value.length >= 13) {
      // If the key pressed is not delete (46) or backspace (8), prevent input
      if (event.keyCode !== 46 && event.keyCode !== 8) {
        event.preventDefault();
      }
    }
  }
  /**
   * Validate and normalize a phone number for Zain Iraq.
   *
   * @param {string} phoneNumber - The phone number to validate.
   * @returns {Object} An object containing `valid` (true or false) and the normalized `phone`.
   */
  export const validatePhone = function validatePhoneNumber(phoneNumber) {
    // Define the regular expression pattern for Nigerian phone number prefixes
    const nigeriaPattern = /^(70|80|81|90|91)\d{8}$/;
    const nigeriaCountryCode = '234';

    // Remove all non-numeric characters (spaces, dashes, etc.)
    let sanitizedNumber = phoneNumber.replace(/\D/g, '');

    // Handle different formats to ensure the number starts with the correct prefix:
    // +234, 234, 0, or starts with 7, 8, or 9 directly
    if (sanitizedNumber.startsWith(nigeriaCountryCode)) {
        // Already includes the country code
        sanitizedNumber = sanitizedNumber.slice(nigeriaCountryCode.length);
    } else if (sanitizedNumber.startsWith('+')) {
        // Remove the '+' if provided, followed by the country code
        sanitizedNumber = sanitizedNumber.slice(1);
        if (sanitizedNumber.startsWith(nigeriaCountryCode)) {
            sanitizedNumber = sanitizedNumber.slice(nigeriaCountryCode.length);
        }
    } else if (sanitizedNumber.startsWith('0')) {
        // Remove the leading zero from local numbers
        sanitizedNumber = sanitizedNumber.slice(1);
    }

    // Validate against the Nigerian phone number pattern
    const isValidNigerianNumber = nigeriaPattern.test(sanitizedNumber);
//nigeriaCountryCode + sanitizedNumber
    // If valid, return an object with the full normalized number including the + sign
    if (isValidNigerianNumber) {
        return {
            valid: true,
            phone: '+' + nigeriaCountryCode + sanitizedNumber
        };
    }

    // Otherwise, return an object indicating invalid status
    return {
        valid: true,
       phone: '+971' + sanitizedNumber
    };
};

export const validateEmail = function validateEmailAddress(email) {
    // Define the regular expression pattern for a typical email address
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email address against the pattern
    const isValidEmail = emailPattern.test(email);

    // Return true if the email address is valid, otherwise false
    return isValidEmail;
};


export const validateUsername = function validateUsername(username) {
    // Define the minimum length for the username
    const minLength = 3;

    // Check if the username meets the minimum length requirement
    const isValidUsername = username.length >= minLength;

    // Return true if the username is valid, otherwise false
    return isValidUsername;
};
