
<template>
  <div class="mt-2 animate-in slide-in-from-left duration-700">
    <swiper
    
      :freeMode="true"
      :modules="modules"
      :slidesPerView="'auto'"
      :spaceBetween="20"
    >
      <swiper-slide v-for="(item, index) in items" :key="item.id">
        <ChannelsItem :item="item" :className="className" :index="index" />
      </swiper-slide>
    </swiper>
  </div>
</template>
  <script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Mousewheel, Keyboard } from "swiper";

import ChannelsItem from "./ChannelsItem.vue";

export default {
  name:"ChannelHome",
  props: ["items", "className"],
  components: {
    Swiper,
    SwiperSlide,
    ChannelsItem,
  },
  setup() {
    return {
      modules: [Mousewheel, Keyboard],
    };
  },
};
</script>
<style scoped>
.swiper {
  width: 100%;
  height: 305px;
}
@media only screen and (max-width: 960px) {
  .swiper {
    width: 100%;
    height: 190px;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 230px !important;
  height: 100%;
  /* Center slide text vertically */

  height: 100%;
}

@media only screen and (max-width: 960px) {
  .swiper-slide {
    text-align: center;
    width: 135px !important;
    height: 100%;
    /* Center slide text vertically */

    height: 100%;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: fill !important;
}
</style>




