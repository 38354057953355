<template>
  <!-- canInstall && !isAppInstalled -->

  <div
    v-if="canInstall && !isAppInstalled"
    class=" md:hidden install-prompt fixed z-50 bottom-[80px] md:top-0 md:h-[80px] px-2 max-w-[960px] min-w-[320px] md:max-w-screen-lg md:min-w-full md:w-[600px] w-full text-sm btn-color rounded-md p-4 flex justify-between items-center space-x-1 bg-white md:animate-none animate-in slide-in-from-left duration-700"
  >
    <div class="flex items-center space-x-2">
      <img
        src="@/assets/AD-Logo.webp"
        class="object-cover size-12"
        alt="logo"
        width="100"
        height="100"
      />
      <p class="text-white text-wrap text-size text-left">
        Jin Daɗin Kallon Mafi Kyau Ta Wajen Manhaja.
      </p>
    </div>
    <div class="flex items-center space-x-2">
      <button
        @click="installApp"
        class="py-1 px-3 bg-white text-[#E54057] rounded-md border border-white shadow-inner text-size"
      >
        Shigar Da Manhaja
      </button>
      <div
        class="text-white text-lg cursor-pointer"
        @click="cancelInstallation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      canInstall: false,
      isAppInstalled: false,
      deferredPrompt: null,
    };
  },
  mounted() {
    // Check if the app is already installed by reading the navigator
    if (window.matchMedia("(display-mode: standalone)").matches) {
      this.isAppInstalled = true;
    }

    // Event listener for the app being installed
    window.addEventListener("appinstalled", this.handleAppInstalled);

    // Listen for the beforeinstallprompt event
    window.addEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
  },
  beforeUnmount() {
    // Cleanup event listeners when the component is destroyed
    window.removeEventListener("appinstalled", this.handleAppInstalled);
    window.removeEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
  },
  methods: {
    cancelInstallation() {
      return (this.canInstall = false);
    },
    handleBeforeInstallPrompt(e) {
      // Prevent the default prompt
      e.preventDefault();
      // Store the event for later use
      this.deferredPrompt = e;
      // Allow the install button to be shown
      this.canInstall = true;
    },
    handleAppInstalled() {
      // Update the state when the app is installed
      this.isAppInstalled = true;
      this.deferredPrompt = null;
    },
    async installApp() {
      if (!this.deferredPrompt) return;

      // Show the install prompt
      this.deferredPrompt.prompt();

      // Wait for the user's response to the prompt
      const choiceResult = await this.deferredPrompt.userChoice;

      // Log the user's response
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }

      // Reset the deferredPrompt variable
      this.deferredPrompt = null;
      this.canInstall = false;
    },
  },
};
</script>

<style scoped>
.install-prompt {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.text-size {
  line-height: normal;
}

@media (min-width: 320px) and (max-width: 360px) {
  .text-size {
    font-size: 11px;
    line-height: normal;
  }
}
@media (min-width: 360px) and (max-width: 960) {
  .text-size {
    font-size: 14px;
  }
}
@media (min-width: 960px) {
  .text-size {
    font-size: 16px;
  }
}
</style>
