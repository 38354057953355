<template>
  <div
    class="absloute  bottom-0 h-24 px-16 mt-8"
    dir="rtl"
  >
    <div class=" flex  justify-between items-center">
      <!-- logo section -->
      <div class="mt-2">
        <router-link to="/">
          <img
            src="@/assets/logo-6.png"
            class="mr-1"
            alt="Nasan Logo"
            width="100"
            height="100"
          />
        </router-link>
    </div>

    <div class="flex  justify-start    text-sm    ">
      <div class="ml-4">
        <a href="#" class="text-gray-300 text-sm "> Sharuɗa da ƙa'idoji </a>
      </div>

      <div class="ml-4">
        <a href="#" class="text-gray-300 text-sm"> Takardar Tsare Sirri </a>
      </div>
      <div class="ml-4">
        <a href="#" class="text-gray-300 text-sm"> Tuntuɓe mu </a>
      </div>
    </div>

        <div class="flex justify-between space-x-4">
          <SvgView />
    </div>
    </div>
    <div class="block absolute bottom-2 lg:left-[48%] left-[45%]    mt-6 " >
      <!-- <hr class="m-2 bg-gray-700 w-full " style=""> -->
      <small class="text-gray-200 text-center text-xs" style="">
        All rights reserved - AD Plus 2024
      </small>
    </div>
  </div>
</template>

<script>
import SvgView from "./SvgView.vue";

export default {
  components: { SvgView },
};
</script>

<style></style>
