<template>
  <div class="mb-4 m-2">
    <HomeLayout :data="false"> </HomeLayout>
    <div class="mt-8 mb-5">
      <p class="text-white text-center font-bold text-xl"> Browse channels </p>
      <div class="border border-b-2 border-color w-40 mx-auto"></div>
    </div>

    <div
      v-show="!loaded"
      class="
        container
        grid grid-cols-2
        xxs:grid-cols-2
        sm:grid-cols-3
        xs-800:grid-cols-5
        md:grid-cols-4
        lg:grid-cols-5
        gap-y-8
        mx-auto
        mt-4
        justify-items-center
      "
      dir="ltr"
    >
      <channels-card
        v-for="(live, index) in livesData"
        :key="index"
        :live="live"
      />
    </div>
    <!--  -->
    <div
      v-show="loaded"
      class="
        container
        grid grid-cols-2
        xxs:grid-cols-2
        sm:grid-cols-3
        xs-800:grid-cols-5
        md:grid-cols-4
        lg:grid-cols-5
        gap-y-8
        mx-auto
        mt-4
        justify-items-center      
      "
      dir="ltr"
    >
      <LoaderView v-for="(index, item) in items" :key="index" :item="item" />
    </div>
  </div>
</template>

<script>
import HomeLayout from "@/components/HomeLayout.vue";
import ChannelsCard from "@/components/cards/ChannelsCard.vue";
import { useLivesStore } from "@/stores/live";
import { mapState, mapActions } from "pinia";
import LoaderView from "@/components/cards/loaders/LoaderView.vue";

export default {
  components: { HomeLayout, ChannelsCard, LoaderView },
  data() {
    return {
      loaded: false,
      items: ["first", "sec", "third", "fourth", "fivth"],
    };
  },
  computed: {
    ...mapState(useLivesStore, ["livesData"]),
  },

  methods: {
    ...mapActions(useLivesStore, ["showLivesData"]),
  },
  async created() {
    this.loaded = true;
    try {
      await this.showLivesData();
      this.loaded = false;
    } catch (error) {
      console.error("Failed to lives  data", error);
    }
  },
};
</script>

<style scoped>
.bg-sub {
  background-color: black;
}
select {
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 1em;
}
</style>