import { createRouter, createWebHistory } from 'vue-router'
import { routes } from "./routes"
// import { AUTHTOKEN } from '@/constants';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, /* eslint-disable */
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  },
})



export default router
