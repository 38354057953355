<template>
  <div
    class="relative rounded-lg w-full h-full shadow-lg mx-2 md:max-h-[26rem] max-h-64 overflow-hidden hover:scale-105 transition duration-400 ease-in-out"
    style="height: 100%"
  >
    <div class="absolute left-2 md:left-6 top-1" v-show="item.label">
      <div class="flex justify-between space-between h-7">
        <div
          class="align-baseline nowrap rounded text-center py-0.3 mt-2 mr-1 text-sm px-1 inline font-normal btn-color md:text-md text-white"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="absolute right-2 md:right-6 top-1">
      <div class="flex justify-between space-between h-7">
        <div
          class="align-baseline nowrap rounded text-center py-0.3 mt-2 mr-1 text-sm px-1 inline font-normal bg-orange-500 md:text-md text-white"
        >
          {{ item.eposides_count +"KASHI" }}
        </div>
      </div>
    </div>
    <router-link :to="`/watch/now/${item.id}`" class="">
      <img
        class="rounded-lg object-cover w-full h-full shadow-lg z-40"
        width="100"
        height="100"
        :src="image(item)"
        :alt="item.title"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      s3: 'https://africatv-systech.s3.af-south-1.amazonaws.com/webapp/series/'
    }
  },
  methods: {
    formateSyntax (count) {
      if (count == 1) {
        return 'حلقة واحدة'
      } else if (count == 2) {
        return 'حلقتان'
      } else if (count > 2 && count < 11) {
        return `${count} حلقات`
      } else {
        return `${count} حلقة`
      }
    },
    image (item) {
     

      if (item.image) {
        return this.s3 + item.image
      } else {
        var images = require.context('@/assets/', false, /\.webp$/)
        return images('./' + 'placeholder-2.webp')
      }
    }
  }
}
</script>

<style></style>
