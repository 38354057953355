
<template>
  <div
    class="flex justify-center rounded-lg mt-12 container mx-auto shadow-md bg-sub drop-shadow-2xl lg:p-2 p-6"
  >
    <div class="">
      <div class="px-4 p-1 rounded-md place-items-center">
        <div class="flex justify-center">
          <img
            src="@/assets/avatar.webp"
            class="rounded-full w-24 h-24 md:w-36 md:h-36"
            alt="avatar"
          />
        </div>
        <div class="mt-2">
          <p class="text-md text-white text-center">حالة الحساب</p>
          <div class="border border-b-4 border-color mx-auto mt-1 w-36"></div>

          <div
            class="m-3 relative md:w-96 mx-auto flex justify-center"
            dir="rtl"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="green"
              class="w-6 h-6 ml-1 -mt-1"
              :class="isActive ? '' : 'hidden'"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="red"
              class="w-6 h-6 ml-1 -mt-1"
              :class="isActive ? 'hidden' : ''"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <p class="text-md text-white text-center">مشترك</p>
          </div>
          <div class="">
            <router-link to="/">
              <button
                class="btn-color text-white text-center rounded-md h-9 px-8 w-56 md:ml-20"
              >
                الصفحة الرئيسية
              </button>
            </router-link>
          </div>
          <div v-if="isActive" class="mt-4">
            <router-link to="/confirm/logout">
              <button
                class="border border-color text-white text-center rounded-md h-9 px-8 w-56 md:ml-20"
              >
                تسجيل خروج
              </button>
            </router-link>
          </div>

          <div v-else>
            <router-link to="/login">
              <button
                class="border border-color text-white text-center rounded-md h-9 px-8 w-56 md:ml-20"
              >
                تسجيل دخول
              </button>
            </router-link>
          </div>

          <div
            class="mt-4 text-center text-red-600 font-semibold text-sm"
            v-if="isActive"
          >
            <router-link to="/confirm/unsubscribe">إلغاء ألإشتراك</router-link>
          </div>
          <div v-else>
            <router-link to="/subscribe/headerinerchment">
              <button
                class="btn-color mt-6 border border-color text-white text-center rounded-md h-9 px-8 w-56"
              >
                اشترك الان
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isDone: false,
      isActive: false,
    };
  },
  mounted() {
    this.checkIsActive();
    
  },
  components: {},
  computed: {},
  methods: {
    checkIsActive() {
      return (this.isActive = localStorage.getItem("isActive"));
    },
  },
};
</script>


<style scoped>
.w-btn {
  width: 290px;
  align-self: right;
}



.bg-sub {
  background-color: #181b30;
}
</style>
