<template>
    <div  v-if="loading">
      <div class=" z-50 flex justify-center items-center ">
   <div class="animate-spin rounded-full  h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
</div>
      </div>
</template>

<script>
export default {
    props:['loading']
}
</script>

<style>

</style>