<template>
  <div class=" absolute left-1/2 -translate-x-1/2 bottom-4 z-20" style="width: max-content;">
    <button
    aria-label="indecators"
      class="carousel-indicator-item bg-white rounded-full border-none md:h-3 md:w-3 h-1.5 w-1.5 m-1 opacity-25"
      :class="{ active: currentIndex === index }"
      v-for="(item, index) in total"
      :key="index"
      @click="$emit('switch', index)"
    ></button>
  </div>
</template>

<script>
export default {
  emits: ["switch"],
  props: ["total", "currentIndex"],
};
</script>

<style scoped>


.active {
  opacity: 1;
  width:40px;
  height:6px;
  background-color: #E54057;
  border-radius: 25px;
  margin-top:4px
}
@media only screen and (max-width: 576px) {
  .active {
  opacity: 1;
  width:30px;
  height:6px;
  background-color: #E54057;
  border-radius: 20px;
  margin-top:6px
}
}
</style>
