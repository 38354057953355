<template>
  <div>
    <p class="textlg text-white">Detected Country: {{ countryName }}</p>
    <p class="textlg text-white">Currency: {{ currency ? currency.name : 'Loading...' }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      countryName: '', // Will be set dynamically
      currency: null
    };
  },
  mounted() {
    this.detectCountryAndCurrency();
  },
  methods: {
    detectCountryAndCurrency() {
      // Replace 'YOUR_API_KEY' with your actual API key from ipapi
      axios.get('https://ipapi.co/json/')
        .then(response => {
          const countryName = response.data.country_name;
          this.countryName = countryName;
          this.getCountryCurrency(countryName);
        })
        .catch(error => {
          console.error('There was an error detecting the country:', error);
        });
    },
    getCountryCurrency(countryName) {
      axios.get(`https://restcountries.com/v3.1/name/${countryName}`)
        .then(response => {
          const countryData = response.data[0];
          if (countryData && countryData.currencies) {
            const currencyCode = Object.keys(countryData.currencies)[0];
            this.currency = countryData.currencies[currencyCode];
          }
        })
        .catch(error => {
          console.error('There was an error fetching the currency information:', error);
        });
    }
  }
};
</script>
