<template>
  <div :class="data ? 'md:h-[80dvh] h-[28rem] overflow-hidden':''" class="relative  ">
      <mobile-view/>
      <slot />
 
 </div>
 

</template>

<script>
import MobileView from './DesktopMenu.vue'

export default {
  components: { MobileView },
  props:['data']

}
</script>

<style>

</style>