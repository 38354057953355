<template>
<div>
    <floating-view/>
  </div>
</template>

<script>
import FloatingView from './FloatingView.vue';
export default {
    components:{FloatingView}
};
</script>

<style>

</style>