import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getSliders } from "@/Gql/sliders";
export const useSlidersStore = defineStore("sliders", {
    state: () => {
        return {
            sliders: Array,
        }
    },
    getters: {
        slidersData: (state) => state.sliders,
      
    },
    actions: {
        async showSlidersData(){
            await apolloClient
            .query({
                    query: getSliders,
                variables: { page: 1, limit: 15 },

            }).then(async res => {
                this.sliders = res.data.getSliders.data.filter((slide)=>slide.published===true)
                // this.filltered();
               
            })
            .catch(error => {
                this.error = error
            })
        },
        async filltered(){
            return this.sliders.filter((slide)=>slide.published===false)
             
        }
    }
})