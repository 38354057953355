<template>
   <svg
        v-show="currentPlaying === item.id"
        version="1.1"
        id="play_sv"
        class="svg-icon"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        height="60px"
        width="60px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
        xml:space="preserve"
      >
        <path
          class="stroke-dotted"
          fill="none"
          stroke="white"
          d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7 C97.3,23.7,75.7,2.3,49.9,2.5"
        ></path>
        <path
          class="icon"
          fill="white"
          d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"
        ></path>
      </svg>
</template>

<script>
export default {
props:['currentPlaying','item']
}
</script>

<style>
.overlay {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0px;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
  right: 0%;
  border-radius: 8px;
}
.stroke-dotted {
  opacity: 1;
  stroke-dasharray: 8, 10;
  stroke: #d50055;
  stroke-width: 4px;
  transform-origin: 50% 50%;
  -webkit-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
  transition: opacity 1s ease, stroke-width 1s ease;
}
.svg-icon {
  position: absolute;
  right: 40%;
  top: 35%;
}

@media only screen and (max-width: 960px) {
  .svg-icon {
    position: absolute;
    right: 33%;
    top: 30%;
  }
}
</style>