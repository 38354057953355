<template>
  <div v-if="serie">
    <div class="flex justify-start md:mx-2 border-b-1 mt-2">
      <div>
        <h6 class="text-lg md:text-2xl  text-white -mb-2 ">
          {{serie.title }}
        </h6>
        <div class="my-2 border-color border border-b-2"></div>
      </div>
    </div>
    <div class="" dir="">
      <p class="text-gray-600 md:text-sm text-xs   w-128  text-left md:ml-2 ml-0" dir="">
        {{ 'An ƙirƙira a :  ' + dateForamts(serie) }}
      </p>
    </div>
    <div class="flex justify-start mx-3 border-b-1 my-2 md:ml-2 ml-0 space-x-3" >
      <h3 class="text-color text-lg ">Ɓangare :</h3>
      <small class="text-white   text-lg text-left">{{
      serie.genre.title
      }}</small>
     
    </div>

    <div class="flex justify-start md:mx-3 border-b-1 my-2 md:ml-2 ml-0
    " dir="ltr">
      <p class="text-gray-400 md:text-sm text-xs font-bold text-left w-128">
        {{ serie.desc }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['serie'],

  components: {},
  methods: {
    dateForamts (serie) {
      if (!serie.createdAt) return 'unkonwn'
      var d = new Date(serie.createdAt),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }
  }
}
</script>

<style></style>
